.visit-us {
  position: relative;
}
.frame-visit-us {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.component-facebook {
  align-self: stretch;
  position: relative;
  max-height: 100%;
  width: 46px;
}
.frame-1-social-media-icons {
  height: 51px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.social-media-links {
  position: inherit;
  top: 0;
  left: calc(50% - 78px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-13xl);
}
.social-media-frame {
  width: 156px;
  height: 70px;
  text-align: left;
  font-size: var(--mobile-body-size);
  color: var(--text-white);
  font-family: var(--mobile-body);
}
