.about-us {
  flex: 1;
  position: relative;
}
.pageheader {
  width: 428px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-mini);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--mobile-h1-size);
  color: var(--text-white);
  font-family: var(--mobile-body);
}

@media screen and (min-width: 768px) {
.pageheader{
  font-size: xx-large
}
  
}