.cmrlogo-icon3,
.menu-icon1 {
  max-width: 100%;
  overflow: hidden;
  height: 65px;
  object-fit: cover;
}
.menu-icon1 {
  height: 48.5px;
}
.property-1moble-navbar-closed1 {
  align-self: stretch;
  background-color: var(--color-black);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.combined-shape-icon2 {
  position: relative;
  width: 35.5px;
  height: 32.5px;
}
.menu2,
.moble-navbar2 {
  display: flex;
  align-items: center;
}
.menu2 {
  margin: 0;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-5xs);
}
.moble-navbar2 {
  align-self: stretch;
  background-color: var(--color-black);
  overflow: hidden;
  flex-direction: row;
  justify-content: space-between;
}
.navbarpic-icon2 {
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 13.5px;
  width: 320px;
  height: 180px;
  object-fit: cover;
  z-index: 0;
}
.about4,
.home4 {
  text-decoration: none;
  position: relative;
  color: inherit;
  z-index: 1;
}
.about4 {
  z-index: 2;
}
.contact4,
.live-entertainment4,
.music-store4,
.studio-services4 {
  text-decoration: none;
  position: relative;
  color: inherit;
  z-index: 3;
}
.contact4,
.music-store4,
.studio-services4 {
  z-index: 4;
}
.contact4,
.music-store4 {
  z-index: 5;
}
.contact4 {
  z-index: 6;
}
.links4 {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 13.495652198791504px;
  position: relative;
  gap: var(--gap-sm-5);
}
.property-1moble-navbar-open-item {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  height: 15px;
  flex-shrink: 0;
  object-fit: cover;
}
.property-1moble-navbar-open1 {
  margin: 0;
  align-self: stretch;
  background-color: var(--color-gray);
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  text-align: left;
  font-size: 17.08px;
  color: var(--text-white);
  font-family: var(--mobile-body);
}
.cmrlogo-icon5 {
  flex: 1;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.menu3,
.moble-navbar3 {
  align-items: center;
  justify-content: flex-start;
}
.menu3 {
  display: none;
  flex-direction: column;
  padding: var(--padding-5xs);
}
.moble-navbar3 {
  background-color: var(--color-black);
  width: 154.9px;
  display: flex;
  flex-direction: row;
  gap: var(--gap-148xl);
}
.navbarpic-icon3 {
  position: absolute;
  margin: 0 !important;
  top: 3px;
  left: 0;
  width: 320px;
  height: 180px;
  object-fit: cover;
  display: none;
  z-index: 0;
}
.links5,
.property-1desktop-view21 {
  flex-direction: row;
  align-items: center;
}
.links5 {
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 0 13.495652198791504px;
  position: relative;
}
.property-1desktop-view21 {
  margin: 0;
  align-self: stretch;
  background-color: var(--color-gray);
  height: 65px;
  display: none;
  justify-content: center;
  gap: var(--gap-5xs);
  text-align: left;
  font-size: 17.08px;
  color: var(--text-white);
  font-family: var(--mobile-body);
}
.navigation-bar1 {
  align-self: stretch;
  border-radius: var(--br-8xs);
  border: 1px dashed var(--color-blueviolet);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.live-entertainment6 {
  flex: 1;
  position: relative;
}
.frame-live-entertainment {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-49xl);
}
.do-me-good1,
.genre1 {
  margin: 0;
  position: relative;
}
.genre1 {
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
}
.do-me-good1 {
  font-size: 27.31px;
  line-height: 27.31px;
  font-weight: 700;
  font-family: var(--font-helvetica);
}
.album-titlegenre-banner1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 13.65px;
  font-family: var(--font-fasthand);
}
.do-me-good-album-cover-11 {
  position: absolute;
  top: 0;
  left: 0;
  width: 320px;
  height: 290.7px;
  object-fit: cover;
}
.image-frame1 {
  position: relative;
  width: 320px;
  height: 290.7px;
}
.button3 {
  position: relative;
  font-size: var(--small-heavy-size);
  line-height: 14px;
  font-weight: 600;
  font-family: var(--small-heavy);
  color: var(--color-gainsboro);
  text-align: left;
}
.button-style-11,
.button-style-21 {
  cursor: pointer;
  border: 0.6px solid var(--color-gainsboro);
  padding: 5.19757604598999px 23.38909149169922px;
  border-radius: 20.79px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.button-style-11 {
  background-color: transparent;
}
.button-style-21 {
  background-color: var(--color-fuchsia);
}
.album-button-banner-child,
.button-style-1-group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.button-style-1-group {
  flex-direction: row;
  gap: 6.5px;
}
.album-button-banner-child {
  flex-direction: column;
  padding: 6.496969699859619px;
}
.price1 {
  position: relative;
  line-height: 20.79px;
}
.album-button-banner1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 36.38px;
  font-size: 15.59px;
}
.description-text1 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 24px;
  display: inline-block;
}
.description-frame1 {
  position: relative;
  width: 264.5px;
  height: 143.4px;
  text-align: justify;
  font-size: var(--mobile-body-size);
}
.arrows-icon4 {
  position: relative;
  border-radius: 20.48px;
  width: 20.5px;
  height: 20.5px;
  overflow: hidden;
  flex-shrink: 0;
}
.navigation-state-child7 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 17.07px;
  background-color: var(--color-darkslategray);
}
.navigation-state10 {
  position: relative;
  width: 10.2px;
  height: 6.8px;
}
.navigation-state-child8 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 17.07px;
  background-color: var(--color-whitesmoke);
}
.navigation-state11 {
  position: relative;
  width: 6.8px;
  height: 6.8px;
}
.navigation-state-container {
  border-radius: 20.48px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6.83px;
}
.pagination1 {
  width: 160.4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.album-carousel-card1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 11.95px;
  background-image: url(/public/album-carousel-card1@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.album-carousel-frame1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  color: var(--color-gainsboro);
  font-family: var(--small-heavy);
}
.cmr-entertainment-music {
  margin-block-start: 0;
  margin-block-end: 6px;
}
.we-look-forward {
  margin: 0;
}
.content-div-container,
.section-2 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.content-div-container {
  flex: 1;
  justify-content: flex-start;
}
.section-2 {
  align-self: stretch;
  border-radius: var(--br-3xs);
  height: 512px;
  flex-shrink: 0;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-11xl);
  box-sizing: border-box;
  background-image: url(/public/section-2@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  font-size: var(--mobile-body-size);
}
.cmrlogo-1-icon2 {
  align-self: stretch;
  position: relative;
  max-height: 100%;
  width: 236.8px;
  object-fit: cover;
}
.cmr-logo-div1 {
  align-self: stretch;
  height: 101px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.visit-us2 {
  position: relative;
}
.frame-visit-us2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.component-facebook2 {
  align-self: stretch;
  position: relative;
  max-height: 100%;
  width: 69.7px;
}
.frame-1-social-media-icons2 {
  height: 77.2px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.social-media-links2 {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 48.4571418762207px;
  font-size: 24.23px;
}
.cmr-entertainment-music-container4,
.cmr-text-inner {
  align-self: stretch;
  position: relative;
}
.cmr-text-inner {
  border-top: 8px solid var(--color-slateblue-200);
  box-sizing: border-box;
  height: 8px;
}
.cmr-text2,
.links6 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs);
  gap: var(--gap-smi);
}
.links6 {
  text-decoration: none;
  width: 224px;
  box-sizing: border-box;
  gap: var(--gap-sm-5);
  font-size: var(--mobile-body-size);
  color: inherit;
}
.frame-child2 {
  position: relative;
  width: 21.7px;
  height: 20.5px;
}
.div4 {
  align-self: stretch;
  flex: 1;
  position: relative;
}
.cmr-text-container,
.frame-parent3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-parent3 {
  width: 224px;
  padding: 0 var(--padding-5xs);
  box-sizing: border-box;
  gap: var(--gap-5xs);
  font-size: var(--font-size-xs);
}
.cmr-text-container {
  align-self: stretch;
  overflow: hidden;
  flex-wrap: wrap;
  gap: var(--gap-mini);
}
.copyright-20152 {
  margin-block-start: 0;
  margin-block-end: 2.79px;
}
.copyright-2015-container2 {
  flex: 1;
  position: relative;
  white-space: pre-wrap;
}
.cmr-rights-reserved2 {
  align-self: stretch;
  border-radius: var(--br-8xs);
  background-color: var(--color-slateblue-100);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--font-size-xs);
}
.mobile-footer-wrapper,
.mobile-footer2 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}
.mobile-footer2 {
  background-color: var(--color-black);
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-11xl) 0 0;
  gap: var(--gap-27xl);
  text-align: left;
  font-size: var(--mobile-h5-size);
  color: var(--text-white);
  font-family: var(--mobile-body);
}
.mobile-footer-wrapper {
  align-items: center;
  justify-content: center;
}
.main-section1,
.music-store21 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.main-section1 {
  align-self: stretch;
  align-items: center;
  gap: var(--gap-50xl);
  text-align: left;
  font-size: 20.48px;
}
.music-store21 {
  position: relative;
  width: 100%;
  height: 926px;
  align-items: flex-start;
  gap: var(--gap-27xl);
  background-image: url(/public/contact-page2@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
  font-size: var(--mobile-h1-size);
  color: var(--text-white);
  font-family: var(--mobile-body);
}

.main-page-music-store{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: var(--text-white);
  font-family: var(--mobile-body);
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  overflow-x: clip;
  gap: var(--gap-13xl);
  background-image: url(/public/contact-page2@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-color: black;
  background-position-y:-20vh ;
  text-align: center;
  font-size: var(--mobile-h1-size);
}

.main-section4 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: var(--text-white);
  font-family: var(--mobile-body);
  align-self: stretch;
  height: 1285.9px;
  align-items: center;
  gap: 0;
  text-align: left;
  font-size: 24.23px;
}





@media screen and (max-width: 1200px) {
  .property-1moble-navbar-closed2 {
    display: none;
  }
  .property-1moble-navbar-open2 {
    display: none;
    gap: var(--gap-0);
    padding-top: 0;
    padding-bottom: var(--padding-5xs);
    box-sizing: border-box;
  }
  .property-1desktop-view22 {
    display: flex;
    align-self: stretch;
    width: auto;
  }
  .cmr-text-parent1 {
    flex-direction: row;
    gap: var(--gap-mini);
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 0;
    box-sizing: border-box;
  }
  .cmr-rights-reserved3,
  .cmr-text-parent1 {
    align-self: stretch;
    width: auto;
  }
  .mobile-footer3 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
@media screen and (max-width: 960px) {
  .property-1moble-navbar-closed2 {
    display: flex;
  }
  .property-1desktop-view22,
  .property-1moble-navbar-open2 {
    display: none;
  }
}
@media screen and (max-width: 420px) {
  .property-1desktop-view22,
  .property-1moble-navbar-open2 {
    display: none;
  }
  .cmr-text-parent1 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}




