@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fasthand:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Helvetica:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  line-height: normal;
}

:root {
  /* fonts */
  --small-heavy: "Open Sans";
  --mobile-body: Inter;
  --font-helvetica: Helvetica;
  --font-fasthand: Fasthand;
  --font-roboto: Roboto;

  /* font sizes */
  --small-heavy-size: 10px;
  --font-size-xs: 12px;
  --mobile-body-size: 16px;
  --font-size-xl-3: 20.3px;
  --font-size-13xl-4: 32.4px;
  --font-size-lg-3: 18.3px;
  --font-size-13xl: 32px;
  --font-size-5xl: 24px;
  --font-size-mid-6: 17.6px;
  --font-size-9xl-8: 28.8px;
  --font-size-lg: 18px;
  --font-size-5xl-2: 24.2px;
  --font-size-13xl-1: 32.1px;
  --font-size-xl-1: 20.1px;
  --font-size-3xl-6: 22.6px;
  --font-size-mid-1: 17.1px;
  --font-size-base-3: 16.3px;

  /* Colors */
  --lime-600: #b6c423;
  --text-white: #fff;
  --color-black: #000;
  --color-slateblue-100: #4947c8;
  --color-slateblue-200: rgba(73, 71, 200, 0.53);
  --color-whitesmoke: #efefef;
  --color-darkslategray: #333;
  --color-gainsboro: #d9d9d9;
  --color-fuchsia: rgba(226, 19, 231, 0.42);
  --color-blueviolet: #9747ff;
  --color-gray: #070707;

  /* Gaps */
  --gap-27xl: 46px;
  --gap-mini: 15px;
  --gap-5xs: 8px;
  --gap-sm-5: 13.5px;
  --gap-smi: 13px;
  --gap-sm: 14px;
  --gap-23xl-6: 42.6px;
  --gap-5xs-6: 7.6px;
  --gap-base: 16px;
  --gap-5xl: 24px;
  --gap-7xs: 6px;
  --gap-11xl: 30px;
  --gap-9xs: 4px;
  --gap-13xl: 32px;
  --gap-50xl: 69px;
  --gap-148xl: 167px;
  --gap-0: 0px;

  /* Paddings */
  --padding-5xs: 8px;
  --padding-11xl: 30px;
  --padding-mini: 15px;
  --padding-5xs-6: 7.6px;
  --padding-6xs-1: 6.1px;
  --padding-8xl-4: 27.4px;
  --padding-4xs: 9px;
  --padding-13xl: 32px;
  --padding-29xl-5: 48.5px;
  --padding-49xl: 68px;
  --padding-sm-5: 13.5px;
  --padding-7xs-2: 5.2px;
  --padding-4xl-4: 23.4px;

  /* Border radiuses */
  --br-8xs: 5px;
  --br-3xs: 10px;
  --br-5xl: 24px;
  --br-xl: 20px;
  --br-5xl-4: 24.4px;
  --br-mid-8: 17.8px;
  --br-mini-9: 14.9px;
  --br-xl-5: 20.5px;
  --br-mid-1: 17.1px;
  --br-xl-8: 20.8px;
  --br-mid: 17px;
  --br-mini-1: 14.1px;
}
