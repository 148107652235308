.swiper-button-next,
.swiper-button-prev {
  color: white;
}

.swiper-pagination-bullet {
  background-color: white;
}

.swiper-button-prev {
  color: white;
}

/* .swiper-button-next {
  color: white;
  content: "next";
  padding-top: 365px;
  padding-right: 400px;
  font-size: 30px;
} */

/* .swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: "next";
  padding-top: 365px;
  padding-right: 400px;
  font-size: 30px;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: "prev";
  padding-top: 365px;
  padding-left: 400px;
  font-size: 30px;
} */
