.cmrlogo-icon6,
.menu-icon2 {
  max-width: 100%;
  overflow: hidden;
  height: 65px;
  object-fit: cover;
}
.menu-icon2 {
  height: 48.5px;
}
.property-1moble-navbar-closed2 {
  align-self: stretch;
  background-color: var(--color-black);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.combined-shape-icon4 {
  position: relative;
  width: 35.5px;
  height: 32.5px;
}
.menu4,
.moble-navbar4 {
  display: flex;
  align-items: center;
}
.menu4 {
  margin: 0;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-5xs);
}
.moble-navbar4 {
  align-self: stretch;
  background-color: var(--color-black);
  overflow: hidden;
  flex-direction: row;
  justify-content: space-between;
}
.navbarpic-icon4 {
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 13.5px;
  width: 320px;
  height: 180px;
  object-fit: cover;
  z-index: 0;
}
.about7,
.home7 {
  text-decoration: none;
  position: relative;
  color: inherit;
  z-index: 1;
}
.about7 {
  z-index: 2;
}
.contact7,
.live-entertainment8,
.music-store7,
.studio-services7 {
  text-decoration: none;
  position: relative;
  color: inherit;
  z-index: 3;
}
.contact7,
.music-store7,
.studio-services7 {
  z-index: 4;
}
.contact7,
.music-store7 {
  z-index: 5;
}
.contact7 {
  z-index: 6;
}
.links7 {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 13.495652198791504px;
  position: relative;
  gap: var(--gap-sm-5);
}
.property-1moble-navbar-open-inner {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  height: 15px;
  flex-shrink: 0;
  object-fit: cover;
}
.property-1moble-navbar-open2 {
  margin: 0;
  align-self: stretch;
  background-color: var(--color-gray);
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  text-align: left;
  font-size: 17.08px;
  color: var(--text-white);
  font-family: var(--mobile-body);
}
.cmrlogo-icon8 {
  flex: 1;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.menu5,
.moble-navbar5 {
  align-items: center;
  justify-content: flex-start;
}
.menu5 {
  display: none;
  flex-direction: column;
  padding: var(--padding-5xs);
}
.moble-navbar5 {
  background-color: var(--color-black);
  width: 154.9px;
  display: flex;
  flex-direction: row;
  gap: var(--gap-148xl);
}
.navbarpic-icon5 {
  position: absolute;
  margin: 0 !important;
  top: 3px;
  left: 0;
  width: 320px;
  height: 180px;
  object-fit: cover;
  display: none;
  z-index: 0;
}
.links8,
.property-1desktop-view22 {
  flex-direction: row;
  align-items: center;
}
.links8 {
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 0 13.495652198791504px;
  position: relative;
}
.property-1desktop-view22 {
  margin: 0;
  align-self: stretch;
  background-color: var(--color-gray);
  height: 65px;
  display: none;
  justify-content: center;
  gap: var(--gap-5xs);
  text-align: left;
  font-size: 17.08px;
  color: var(--text-white);
  font-family: var(--mobile-body);
}
.navigation-bar2 {
  align-self: stretch;
  border-radius: var(--br-8xs);
  border: 1px dashed var(--color-blueviolet);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.about-page {
  flex: 1;
  position: relative;
}
.frame-about-us {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 67px;
}
.main-section-child {
  border-radius: 25.1px;
  max-height: 100%;
  width: 320px;
  object-fit: cover;
}
.do-you-need3 {
  margin-block-start: 0;
  margin-block-end: 6px;
}
.we-look-forward1 {
  margin: 0;
}
.content-div-frame,
.section-11 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.content-div-frame {
  flex: 1;
  justify-content: flex-start;
}
.section-11 {
  align-self: stretch;
  border-radius: var(--br-3xs);
  height: 512px;
  flex-shrink: 0;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-11xl);
  box-sizing: border-box;
  background-image: url(/public/section-11@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.cmrlogo-1-icon3 {
  align-self: stretch;
  position: relative;
  max-height: 100%;
  width: 236.8px;
  object-fit: cover;
}
.cmr-logo-div2 {
  align-self: stretch;
  height: 101px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.visit-us3 {
  position: relative;
}
.frame-visit-us3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.component-facebook3 {
  align-self: stretch;
  position: relative;
  max-height: 100%;
  width: 69.7px;
}
.frame-1-social-media-icons3 {
  height: 77.2px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.social-media-links3 {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 48.4571418762207px;
  font-size: 24.23px;
}
.cmr-entertainment-music-container5,
.line-div {
  align-self: stretch;
  position: relative;
}
.line-div {
  border-top: 8px solid var(--color-slateblue-200);
  box-sizing: border-box;
  height: 8px;
}
.cmr-text3,
.links9 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs);
  gap: var(--gap-smi);
}
.links9 {
  text-decoration: none;
  width: 224px;
  box-sizing: border-box;
  gap: var(--gap-sm-5);
  font-size: var(--mobile-body-size);
  color: inherit;
}
.frame-child3 {
  position: relative;
  width: 21.7px;
  height: 20.5px;
}
.div5 {
  align-self: stretch;
  flex: 1;
  position: relative;
}
.cmr-text-parent1,
.frame-parent4 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-parent4 {
  width: 224px;
  padding: 0 var(--padding-5xs);
  box-sizing: border-box;
  gap: var(--gap-5xs);
  font-size: var(--font-size-xs);
}
.cmr-text-parent1 {
  align-self: stretch;
  overflow: hidden;
  flex-wrap: wrap;
  gap: var(--gap-mini);
}
.copyright-20153 {
  margin-block-start: 0;
  margin-block-end: 2.79px;
}
.copyright-2015-container3 {
  flex: 1;
  position: relative;
  white-space: pre-wrap;
}
.cmr-rights-reserved3 {
  align-self: stretch;
  border-radius: var(--br-8xs);
  background-color: var(--color-slateblue-100);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--font-size-xs);
}
.main-section2,
.mobile-footer3,
.studio-services-page-2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.mobile-footer3 {
  align-self: stretch;
  background-color: var(--color-black);
  align-items: flex-start;
  padding: var(--padding-11xl) 0 0;
  gap: var(--gap-27xl);
  text-align: left;
  font-size: var(--mobile-h5-size);
  color: var(--text-white);
  font-family: var(--mobile-body);
}
.main-section2,
.studio-services-page-2 {
  height: 926px;
}
.main-section2 {
  align-self: stretch;
  align-items: center;
  gap: var(--gap-50xl);
  text-align: left;
  font-size: var(--mobile-body-size);
}
.studio-services-page-2 {
  position: relative;
  width: 100%;
  align-items: flex-start;
  gap: var(--gap-13xl);
  background-image: url(/public/contact-page2@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
  font-size: var(--mobile-h1-size);
  color: var(--text-white);
  font-family: var(--mobile-body);
}


.main-page-studio-services{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: var(--text-white);
  font-family: var(--mobile-body);
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  overflow-x: clip;
  gap: var(--gap-13xl);
  background-image: url(/public/contact-page2@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-color: black;
  background-position-y:-20vh ;
  text-align: center;
  font-size: var(--mobile-h1-size);
}

.main-section4 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: var(--text-white);
  font-family: var(--mobile-body);
  align-self: stretch;
  height: 1285.9px;
  align-items: center;
  gap: 0;
  text-align: left;
  font-size: 24.23px;
}





@media screen and (max-width: 1200px) {
  .property-1moble-navbar-closed2 {
    display: none;
  }
  .property-1moble-navbar-open2 {
    display: none;
    gap: var(--gap-0);
    padding-top: 0;
    padding-bottom: var(--padding-5xs);
    box-sizing: border-box;
  }
  .property-1desktop-view22 {
    display: flex;
    align-self: stretch;
    width: auto;
  }
  .cmr-text-parent1 {
    flex-direction: row;
    gap: var(--gap-mini);
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 0;
    box-sizing: border-box;
  }
  .cmr-rights-reserved3,
  .cmr-text-parent1 {
    align-self: stretch;
    width: auto;
  }
  .mobile-footer3 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
@media screen and (max-width: 960px) {
  .property-1moble-navbar-closed2 {
    display: flex;
  }
  .property-1desktop-view22,
  .property-1moble-navbar-open2 {
    display: none;
  }
}
@media screen and (max-width: 420px) {
  .property-1desktop-view22,
  .property-1moble-navbar-open2 {
    display: none;
  }
  .cmr-text-parent1 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
