/* Forward-direction css selectors */
.description-frame {
  position: relative;
  width: 310px;
  height: 192px;
  margin-top: 3%;
  margin-bottom: 20px;
  overflow: auto;
  text-align: start;
  font-size: var(--mobile-body-size);
  color: white;
}
.description-text {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 24px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.album-title,
.genre {
  margin: 0;
  position: relative;
}
.genre {
  margin-top: 10px;
  font-size: inherit;
  font-weight: 400;
  font-family: sans-serif;
}
.album-title {
  font-size: var(--font-size-13xl);
  line-height: 32px;
  font-weight: 700;
  font-family: var(--font-helvetica);
}
.album-titlegenre-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-family: var(--font-fasthand);
}

.icon {
  display: flex;
  top: 29.7px;
  left: 668.9px;
  border-radius: 17.84px;
  width: 297.3px;
  height: 297.3px;
  object-fit: cover;
  display: flex;
  z-index: -50;
  transform: rotateY(145deg);
  animation: icon-to-icon1 2s ease-in forwards;
  /* animation-play-state: paused; */
}

.icon1 {
  display: flex;
  top: 32.3px;
  left: 0;
  border-radius: 17.84px;
  width: 297.3px;
  height: 297.3px;
  object-fit: cover;
  transform: rotateY(0deg);
  transform-style: preserve-3d;
  animation: icon1-to-icon2 2s ease-in forwards;
  /* animation-play-state: paused; */
}

.icon2 {
  transition: transform 2s;
  display: flex;
  top: 17.5px;
  left: 118.9px;
  width: 327px;
  height: 327px;
  transform: rotateY(45deg);
  animation: icon2-to-icon4 2s ease-in forwards;
  /* animation-play-state: paused; */
}

.icon3 {
  display: flex;
  position: absolute;
  top: 17.5px;
  left: 520.3px;
  width: 327px;
  height: 327px;
  transform: rotateY(145deg);
  transform-style: preserve-3d;
  animation: icon3-to-icon 2s ease-in forwards;
  /* animation-play-state: paused; */
}
.icon4 {
  display: flex;
  position: relative;
  width: 325.4px;
  height: 356.8px;
  transform: rotateY("180deg");
}
.image-2 {
  position: absolute;
  top: 0;
  left: calc(50% - 162.75px);
  width: 325.4px;
  display: flex;
  flex-direction: row;
  align-items: normal;
  justify-content: flex-start;
  transform: rotateY(0deg);
  transform-style: preserve-3d;
  animation: icon4-to-icon3 2s ease-in forwards;
  /* animation-play-state: paused; */
}
.icon,
.icon1,
.icon2 {
  position: absolute;
}

.icon2,
.icon3,
.icon4 {
  border-radius: 17.84px;
  object-fit: cover;
}

.front {
  backface-visibility: hidden;
  z-index: 2;
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  transform: rotateY(0deg);
}

.back {
  position: absolute;
  object-fit: cover;
  width: inherit;
  height: inherit;
  border-radius: inherit;

  transform: rotateY(180deg);
}

/* Reverse-direction css selectors */

.icon-reverse {
  display: flex;
  position: absolute;
  top: 29.7px;
  left: 668.9px;
  border-radius: 17.84px;
  width: 297.3px;
  height: 297.3px;
  object-fit: cover;
  display: flex;
  z-index: -50;
  transform: rotateY(145deg);
  animation: icon-to-icon1 2s ease-in reverse;
  /* animation-play-state: paused; */
}

.icon1-reverse {
  display: flex;
  position: absolute;
  top: 32.3px;
  left: 0;
  border-radius: 17.84px;
  width: 297.3px;
  height: 297.3px;
  object-fit: cover;
  z-index: none;
  transform: rotateY(0deg);
  animation: icon1-to-icon2 2s ease-in reverse;
  /* animation-play-state: paused; */
}

.icon2-reverse {
  display: flex;
  position: absolute;
  top: 17.5px;
  left: 118.9px;
  width: 327px;
  height: 327px;
  border-radius: 17.84px;
  object-fit: cover;
  z-index: 50;
  transform: rotateY(45deg);
  animation: icon2-to-icon4 2s ease-in reverse;
  /* animation-play-state: paused; */
}

.icon3-reverse {
  display: flex;
  position: absolute;
  top: 17.5px;
  left: 520.3px;
  width: 327px;
  height: 327px;
  border-radius: 17.84px;
  object-fit: cover;
  transform: rotateY(145deg);
  animation: icon3-to-icon 2s ease-in reverse;
  /* animation-play-state: paused; */
}
.icon4-reverse {
  display: flex;
  position: relative;
  width: 325.4px;
  height: 356.8px;
  border-radius: 17.84px;
  object-fit: cover;
  z-index: 100;
}
.image-2-reverse {
  display: flex;
  position: absolute;
  top: 0;
  left: calc(50% - 162.75px);
  width: 325.4px;
  flex-direction: row;
  align-items: normal;
  justify-content: flex-start;
  z-index: 100;
  transform: rotateY(0deg);
  animation: icon4-to-icon3 2s ease-in reverse;
  /* animation-play-state: paused; */
}

@keyframes flip1 {
  from {
    backface-visibility: hidden;
    z-index: 2;
    position: absolute;
    object-fit: cover;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    transform: rotateY(0deg);
  }

  to {
    position: absolute;
    object-fit: cover;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    transform: rotateY(180deg);
  }
}

@keyframes icon1-to-icon2 {
  from {
    top: 32.3px;
    left: 0;
    border-radius: 17.84px;
    width: 297.3px;
    height: 297.3px;
    object-fit: cover;
    transform: rotateY(0deg);
    z-index: -50;
  }
  to {
    top: 17.5px;
    left: 118.9px;
    width: 327px;
    height: 327px;
    transform: rotateY(45deg);
  }
}

@keyframes icon-to-icon1 {
  from {
    top: 29.7px;
    left: 668.9px;
    border-radius: 17.84px;
    width: 297.3px;
    height: 297.3px;
    object-fit: cover;
    display: flex;
    z-index: -50;
    transform: rotateY(145deg);
  }

  to {
    top: 32.3px;
    left: 0;
    border-radius: 17.84px;
    width: 297.3px;
    height: 297.3px;
    object-fit: cover;
    z-index: -50;
    transform: rotateY(0deg);
  }
}
@keyframes icon3-to-icon {
  from {
    position: absolute;
    top: 17.5px;
    left: 520.3px;
    width: 327px;
    height: 327px;
    transform: rotateY(145deg);
  }
  to {
    top: 29.7px;
    left: 668.9px;
    border-radius: 17.84px;
    width: 297.3px;
    height: 297.3px;
    object-fit: cover;
    display: flex;
    z-index: -50;
    transform: rotateY(145deg);
  }
}

@keyframes icon2-to-icon4 {
  from {
    transform: rotateY(45deg);
  }
  to {
    position: relative;
    top: 0;
    left: calc(50% - 162.75px);
    width: 325.4px;
    height: 356.8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 100;
    transform: rotateY(0deg);
  }
}

@keyframes icon4-to-icon3 {
  from {
    position: relative;
    top: 0;
    left: calc(50% - 162.75px);
    width: 325.4px;
    height: 356.8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 100;
    transform: rotateY(0deg);
  }
  to {
    position: absolute;
    top: 17.5px;
    left: 520.3px;
    width: 327px;
    height: 327px;
    border-radius: 17.84px;
    object-fit: cover;
    transform: rotateY(145deg);
  }
}

.arrows-icon2,
.frame {
  position: relative;
  width: 966.3px;
  height: 356.8px;
  perspective: 1000px;
}
.arrows-icon2 {
  border-radius: 17.84px;
  width: 17.8px;
  height: 17.8px;
  overflow: hidden;
  flex-shrink: 0;
}
.navigation-state-child2 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 14.87px;
  background-color: var(--color-darkslategray);
}
.navigation-state5 {
  position: relative;
  width: 8.9px;
  height: 5.9px;
}
.navigation-state-child3 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 14.87px;
  background-color: var(--color-whitesmoke);
}
.navigation-state6 {
  position: relative;
  width: 5.9px;
  height: 5.9px;
}
.navigation-state-group {
  border-radius: 17.84px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5.95px;
}
.arrows-parent,
.desktop-carousel {
  width: 139.7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.arrows-parent {
  margin-top: 25%;
}
.desktop-carousel {
  position: unset;
  top: 0;
  left: 0;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  gap: var(--gap-5xl);
}
.desktop-carousel-frame {
  width: 1440px;

  /* height: 398.6px; */
}

/* Button Banner */

.album-desktop-button-banner {
  /* Layout */
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  z-index: 2;
  position: absolute;
  top: 93%;
  padding-top: 10%;
  animation: button-animation 5s ease-in-out forwards;
}

.album-button-container {
  /* Layout */
  display: flex;
  width: 100%;

  justify-content: space-evenly;
}

.play-sample-button {
  /* Layout */
  display: flex;
  width: 100px;
  height: 35px;
  justify-content: center;
  align-items: center;
  gap: 3.9px;

  /* Style */
  border-radius: 31.203px;
  border: 0.975px solid #d9d9d9;
  background: #30437c;

  /* Typography */
  color: #d9d9d9;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Open Sans";
  font-size: 9.751px;
  font-style: normal;
  font-weight: 600;
  line-height: 13.651px; /* 140% */
}

.buy-button {
  /* Layout */
  display: flex;
  width: 100px;
  height: 35px;
  justify-content: center;
  align-items: center;
  gap: 3.9px;

  /* Style */
  border-radius: 31.203px;
  border: 0.975px solid #d9d9d9;
  background: rgba(226, 19, 231, 0.42);

  /* Typography */
  color: #d9d9d9;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Open Sans";
  font-size: 9.751px;
  font-style: normal;
  font-weight: 600;
  line-height: 13.651px; /* 140% */
}

.album-desktop-button-banner-reverse-animation {
  display: flex;
  opacity: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  z-index: 2;
  position: absolute;
  top: 93%;
  padding-top: 10%;
}

@keyframes button-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
