.main-section4 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: var(--text-white);
  font-family: var(--mobile-body);
  align-self: stretch;
  height: 1285.9px;
  align-items: center;
  gap: 0;
  text-align: left;
  font-size: 24.23px;
}
.main-page-live {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: var(--text-white);
  font-family: var(--mobile-body);
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  overflow-x: clip;
  gap: var(--gap-13xl);
  background-image: url(/public/contact-page2@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-color: black;
  background-position-y: -20vh;
  text-align: center;
  font-size: var(--mobile-h1-size);
}
.card-component-child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  border-radius: 6.37px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.text {
  position: relative;
  line-height: 85.2%;
}
.amet-minim-mollit {
  width: 248.64px;
  position: relative;
  font-size: 10.22px;
  display: inline-block;
  opacity: 0.8;
}
.text-parent {
  position: absolute;
  top: 108.99px;
  left: 21.29px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6.81px 0px;
}
.card-component {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  border-radius: 6.81px;
}
.card-states-1 {
  width: 281px;
  position: relative;
  height: 174px;
}
.card-component-inner {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  border-radius: 7.48px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.amet-minim-mollit2 {
  width: 292px;
  position: relative;
  font-size: 12px;
  display: inline-block;
  opacity: 0.8;
}
.text-container {
  position: absolute;
  top: 128px;
  left: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px 0px;
}
.card-component2 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  border-radius: 8px;
}
.card-states-3 {
  width: 330px;
  position: relative;
  height: 200px;
  font-size: 14px;
}
.card-states-1-parent {
  position: absolute;
  top: 0px;
  left: -556px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0px 12px;
}
.component-3 {
  width: 100%;
  position: relative;
  height: 200px;
  text-align: left;
  font-size: 11.92px;
  color: #fff;
  font-family: Inter;
}
