.cmrlogo-icon19 {
  max-width: 100%;
  overflow: hidden;
  height: 65px;
  object-fit: cover;
}
.combined-shape-icon12 {
  position: relative;
  width: 35.5px;
  height: 32.5px;
  transform: rotate(90deg);
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}
@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(90deg);}
}
.menu12,
.moble-navbar12 {
  display: flex;
  align-items: center;
}
.menu12 {
  margin: 0;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-5xs);
}
.moble-navbar12 {
  align-self: stretch;
  background-color: var(--color-black);
  overflow: hidden;
  flex-direction: row;
  justify-content: space-between;
}
.navbarpic-icon12 {
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 13.5px;
  width: 320px;
  height: 180px;
  object-fit: cover;
  z-index: 0;
}
.about18,
.home18 {
  text-decoration: none;
  position: relative;
  color: inherit;
  z-index: 1;
}
.about18 {
  z-index: 2;
}
.contact18,
.live-entertainment20,
.music-store18,
.studio-services18 {
  text-decoration: none;
  position: relative;
  color: inherit;
  z-index: 3;
}
.contact18,
.music-store18,
.studio-services18 {
  z-index: 4;
}
.contact18,
.music-store18 {
  z-index: 5;
}
.contact18 {
  z-index: 6;
}
.links18 {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 13.495652198791504px;
  position: relative;
  gap: var(--gap-sm-5);
}
.property-1moble-navbar-open-child4 {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  height: 15px;
  flex-shrink: 0;
  object-fit: cover;
}
.property-1moble-navbar-open6 {
  margin: 0;
  align-self: stretch;
  background-color: var(--color-gray);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  text-align: left;
  font-size: 17.08px;
  color: var(--text-white);
  font-family: var(--mobile-body);
}
@media screen and (max-width: 1200px) {
  .property-1moble-navbar-open6 {
    display: none;
    gap: var(--gap-0);
    padding-top: 0;
    /* padding-bottom: var(--padding-5xs); */
    box-sizing: border-box;
   
  }
}
@media screen and (max-width: 960px) {
  .property-1moble-navbar-open6 {
    display: flex;
  }
}
