.cmrlogo-icon15,
.property-1desktop-view25 {
  margin: 0;
  align-self: stretch;
  background-color: var(--color-gray);
  height: 65px;
  display: none;
  justify-content: center;
  gap: var(--gap-5xs);
  text-align: left;
  font-size: 17.08px;
  color: var(--text-white);
  font-family: var(--mobile-body);
}
.navigation-bar5 {
  align-self: stretch;
  border-radius: var(--br-8xs);
  border: 1px dashed var(--color-blueviolet);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.feel-the-music {
  align-self: stretch;
  flex: 1;
  position: relative;
}
.feel-the-music-wrapper {
  align-self: stretch;
  height: 40.8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.your-source-for {
  margin: 0;
}
.section-12,
.your-source-for-live-entertain-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}
.your-source-for-live-entertain-wrapper {
  align-self: stretch;
  height: 40.8px;
  flex-direction: row;
  padding: 0 17.16732406616211px;
  font-size: 17.38px;
  color: rgba(203, 179, 179, 0.64);
  font-family: var(--font-roboto);
}
.section-12 {
  margin: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 320px;
  height: 86px;
  flex-direction: column;
  padding: 0 8.583662033081055px;
  gap: 9.44px;
  z-index: 0;
}
.cmr-logo-div5,
.cmrlogo-1-icon5 {
  position: absolute;
  width: 142.7px;
  height: 60.9px;
}
.cmrlogo-1-icon5 {
  top: 0;
  left: 0;
  object-fit: cover;
  display: none;
}
.cmr-logo-div5 {
  margin: 0 !important;
  top: 520.2px;
  left: calc(50% - 71px);
  background-image: url(/public/cmr-logo-div1@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
}
.radical-gradient-div-child {
  flex: 1;
  position: relative;
  background: radial-gradient(
    50% 50%at 50% 50%,
    #000,
    rgba(73, 71, 200, 0.79) 44.79%,
    transparent
  );
  height: 64.3px;
}
.radical-gradient-div {
  margin: 0 !important;
  position: absolute;
  width: 100%;
  right: 0;
  bottom: -0.3px;
  left: 0;
  height: 64.3px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 2;
}
.iphone-13-mini-frame-home,
.main-section5 {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.main-section5 {
  align-self: stretch;
  flex: 1;
  flex-direction: row;
  padding: var(--padding-5xs);
}
.iphone-13-mini-frame-home {
  width: 100%;
  height: 926px;
  overflow-y: auto;
  flex-direction: column;
  padding: var(--padding-5xs) 0;
  box-sizing: border-box;
  background-image: url(/public/iphone-13-mini-frame-home@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: left;
  font-size: 27.85px;
  color: #cbb3b3;
  font-family: var(--mobile-body);
}

@media screen and (max-width: 2200px) {
  .radical-gradient-div {
    display: none;
  }

  .property-1desktop-view25 {
    display: flex;
    align-self: stretch;
    width: auto;
  }
  .iphone-13-mini-frame-home {
    width: 100%;
    background-position-y: 30%;
  }
  .cmr-logo-div5 {
    top: 60%;
  }
}
@media screen and (max-width: 960px) {
  .property-1desktop-view25 {
    display: none;
  }
  .radical-gradient-div {
    display: flex;
    padding-top: 300px;
    box-sizing: border-box;
  }
  .iphone-13-mini-frame-home {
    width: 100%;
    height: 926px;
    flex-direction: column;
  }
  .cmr-logo-div5 {
    top: 60%;
  }
}
@media screen and (max-width: 420px) {
  .property-1desktop-view25 {
    display: none;
  }
  .iphone-13-mini-frame-home {
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
    box-sizing: border-box;
  }
}
