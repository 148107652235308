.cmr5 {
  margin-block-start: 0;
  margin-block-end: 6px;
}
.music-productions6 {
  margin: 0;
}
.cmr-entertainment-music-container6,
.cmr-text-child2 {
  align-self: stretch;
  position: relative;
  color: inherit;
  text-decoration: none;
}

.cmr-entertainment-music-container6:hover {
  color: hotpink;
}
.cmr-entertainment-music-container6:active {
  color: blue;
}

.cmr-text-child2 {
  border-top: 8px solid var(--color-slateblue-200);
  box-sizing: border-box;
  height: 8px;
}
.cmr-text5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs);
  gap: var(--gap-smi);
}
.about17 {
  position: relative;
}
.links17 {
  text-decoration: none;
  width: 224px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs);
  box-sizing: border-box;
  gap: var(--gap-sm-5);
  font-size: var(--mobile-body-size);
  color: inherit;
}
.frame-child4 {
  position: relative;
  width: 21.7px;
  height: 20.5px;
}
.div6 {
  align-self: stretch;
  flex: 1;
  position: relative;
}
.cmr-text-parent2,
.frame-parent5 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-parent5 {
  width: 224px;
  padding: 0 var(--padding-5xs);
  box-sizing: border-box;
  gap: var(--gap-5xs);
  font-size: var(--font-size-xs);
}
.cmr-text-parent2 {
  align-self: stretch;
  overflow: hidden;
  flex-wrap: none;
  gap: var(--gap-mini);
}
.copyright-20155 {
  margin-block-start: 0;
  margin-block-end: 2.79px;
}
.copyright-2015-container5 {
  flex: 1;
  position: relative;
  white-space: pre-wrap;
}
.cmr-rights-reserved5 {
  border-radius: var(--br-8xs);
  background-color: var(--color-slateblue-100);
  width: 100%;
  height: 33px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--font-size-xs);
}
.footer-frame,
.mobile-footer5 {
  display: flex;
  flex-direction: column;
}
.mobile-footer5 {
  align-self: stretch;
  background-color: var(--color-black);
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-11xl) 0 0;
  gap: var(--gap-27xl);
  text-align: left;
  font-size: var(--mobile-h5-size);
  color: var(--text-white);
  font-family: var(--mobile-h1);
}
.footer-frame {
  display: inherit;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1200px) {
  .cmr-text-parent2 {
    flex-direction: row;
    gap: var(--gap-mini);
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 0;
    box-sizing: border-box;
  }
  .cmr-rights-reserved5,
  .cmr-text-parent2 {
    align-self: stretch;
    width: auto;
  }
  .mobile-footer5 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
@media screen and (max-width: 420px) {
  .cmr-text-parent2 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

@media screen and (min-width: 768px) {
  .links17 {
    justify-content: space-around;
  }

  .cmr-text-parent2,
  .frame-parent5 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
  }
}
