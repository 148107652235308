.cmrlogo-icon20 {
  flex: 1;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.combined-shape-icon13 {
  position: relative;
  width: 35.5px;
  height: 32.5px;
}
.menu13,
.moble-navbar13 {
  align-items: center;
  justify-content: flex-start;
}
.menu13 {
  display: none;
  flex-direction: column;
  padding: var(--padding-5xs);
}
.moble-navbar13 {
  background-color: var(--color-black);
  width: 154.9px;
  display: flex;
  flex-direction: row;
  gap: var(--gap-148xl);
}
.navbarpic-icon13 {
  position: absolute;
  margin: 0 !important;
  top: 3px;
  left: 0;
  width: 320px;
  height: 180px;
  object-fit: cover;
  display: none;
  z-index: 0;
}
.about19,
.home19 {
  text-decoration: none;
  position: relative;
  color: inherit;
  z-index: 1;
}
.about19 {
  z-index: 2;
}
.contact19,
.live-entertainment21,
.music-store19,
.studio-services19 {
  text-decoration: none;
  position: relative;
  color: inherit;
  z-index: 3;
}
.contact19,
.music-store19,
.studio-services19 {
  z-index: 4;
}
.contact19,
.music-store19 {
  z-index: 5;
}
.contact19 {
  z-index: 6;
}
.links19,
.property-1desktop-view26 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.links19 {
  flex: 1;
  justify-content: space-between;
  padding: 0 13.495652198791504px;
  position: relative;
}

.property-1desktop-view26 {
  margin: 0;
  align-self: stretch;
  background-color: var(--color-gray);
  height: 65px;
  justify-content: center;
  gap: var(--gap-5xs);
  text-align: left;
  font-size: 17.08px;
  color: var(--text-white);
  font-family: var(--mobile-body);
}
.desktopNavbar {
  display: flex;
}

.desktopNavBar:hover {
  color: hotpink;
}
.desktopNavBar:active {
  color: blue;
}

@media screen and (max-width: 1200px) {
  .property-1desktop-view26 {
    align-self: stretch;
    width: auto;
  }
}
@media screen and (max-width: 960px) {
  .property-1desktop-view26 {
    display: none;
  }
}
@media screen and (max-width: 420px) {
  .property-1desktop-view26 {
    display: none;
  }
}
