.do-me-good,
.genre {
  margin: 0;
  position: relative;
}
.genre {
  font-size: inherit;
  font-weight: 400;
  font-family: sans-serif;
  padding-top: 10px;
}
.do-me-good {
  font-size: var(--font-size-13xl);
  line-height: 32px;
  font-weight: 700;
  font-family: var(--font-helvetica);
}
.album-titlegenre-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-family: var(--font-fasthand);
}
.do-me-good-album-cover-1 {
  position: relative;

  width: 90%;
  /* height: 90%; */
  object-fit: contain;
  object-position: bottom;
  border-radius: 3%;
}
.image-frame {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  /* height: 340.6px; */
}
.button1 {
  position: relative;
  font-size: var(--small-heavy-size);
  line-height: 14px;
  font-weight: 600;
  font-family: var(--small-heavy);
  color: var(--color-gainsboro);
  text-align: left;
}
.button-style-1,
.button-style-2 {
  cursor: pointer;
  border: 0.8px solid var(--color-gainsboro);
  padding: 6.090909004211426px 27.409090042114258px;
  border-radius: 24.36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.button-style-1 {
  background-color: transparent;
}
.button-style-2 {
  background-color: var(--color-fuchsia);
}
.album-button-banner-inner,
.button-style-1-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.button-style-1-parent {
  flex-direction: row;
  gap: 7.61px;
}
.album-button-banner-inner {
  flex-direction: column;
  padding: 7.613636016845703px;
}
.price {
  position: relative;
  line-height: 24.36px;
}
.album-button-banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 42.64px;
  font-size: 18.27px;
}
.description-text {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 24px;
  display: inline-block;
}
.description-frame {
  position: relative;
  width: 310px;
  /* height: 168px; */
  margin-bottom: 20px;
  text-align: start;
  font-size: var(--mobile-body-size);
}
.arrows-icon {
  position: relative;
  border-radius: var(--br-5xl);
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.navigation-state-child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-darkslategray);
}
.navigation-state {
  position: relative;
  width: 12px;
  height: 8px;
}
.navigation-state-item {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-whitesmoke);
}
.navigation-state1 {
  position: relative;
  width: 8px;
  height: 8px;
}
.navigation-state-parent {
  border-radius: var(--br-5xl);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.pagination {
  width: 188px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.album-carousel-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
  background-image: url(/public/album-carousel-card@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.album-carousel-frame {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--color-gainsboro);
  font-family: var(--small-heavy);
}

@media screen and (min-width: 420px) {
  .image-frame {
    width: 95%;
  }
}
