.navigation-bar6 {
  border-radius: var(--br-8xs);
  /*border: 1px dashed var(--color-blueviolet);*/
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

button {
  appearance: auto;

  text-rendering: auto;
  color: buttontext;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  cursor: default;
  box-sizing: border-box;
  background-color: transparent;
  margin: 0em;
  padding: 0 0px;
  border-width: 2px;
  border-style: outset;
  border-color: buttonborder;
  border-image: initial;
  border: 0;
}
