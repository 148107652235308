


.main-section4 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: var(--text-white);
  font-family: var(--mobile-body);
  align-self: stretch;
  height: 1285.9px;
  align-items: center;
  gap: 0;
  text-align: left;
  font-size: 24.23px;
}
.main-page-contact {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: var(--text-white);
  font-family: var(--mobile-body);
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  overflow-x: clip;
  gap: var(--gap-13xl);
  background-image: url(/public/contact-page2@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-color: black;
  background-position-y:-20vh ;
  text-align: center;
  font-size: var(--mobile-h1-size);
}
@media screen and (max-width: 1200px) {
 
}
@media screen and (max-width: 960px) {
  
  }
  

@media screen and (max-width: 420px) {
  
}




