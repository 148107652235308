.cmrlogo-1-icon {
  position: inherit;
  height: 100%;
  top: 0;
  bottom: 0;
  left: calc(50% - 77.45px);
  max-height: 100%;
  width: 154.9px;
  object-fit: cover;
}
.cmr-logo {
  position: inherit;
  top: 0;
  left: 0;
}
.cmr-logo,
.logo-frame {
  width: 154.9px;
  height: 65px;
}
