.cmr-entertainment {
  margin-block-start: 0;
  margin-block-end: 6px;
}
.music-productions1 {
  margin: 0;
}
.cmr-entertainment-music-container1 {
  position: none;
  top: 0;
  left: 0;
}
.cmr-entertainment-music-produc-wrapper {
  align-self: stretch;
  position: relative;
  height: 76px;
}
.richlands-nc {
  position: relative;
}
.frame-group,.frame-group2,
.po-box-1415-parent {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.po-box-1415-parent {
  display: flex;
  font-size:large;
}
.frame-group {
  align-self: center;
  display: flex;
  gap: var(--gap-5xs);
  padding-left: 10px;
}
.frame-group2 {
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: var(--gap-5xs);
}

.frances-rouse {
  position: relative;
  font-size: var(--font-size-lg);
}
.manager-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-item {
  position: relative;
  width: 24px;
  height: 21.4px;
}
.frame-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
  font-size: var(--font-size-lg);
}
.frame-inner {
  position: absolute;
  top: 1.2px;
  left: 0;
  width: 19.5px;
  height: 19.5px;
}
.cmrcmrentertainmentnet {
  position: absolute;
  top: 0;
  left: 22.5px;
}
.group-parent {
  position: relative;
  width: 251px;
  height: 21.3px;
  font-size: 17.56px;
}
.contact-info {
  position: absolute;
  height: auto;
  width: 100%;
  top: 0px;
  right: 0;
  bottom: 12.65%;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8.97746467590332px 0 0;
  padding-top: 25px;
  box-sizing: border-box;
  gap: var(--gap-11xl);
  text-align: left;
  font-size: 28.83px;
  color: var(--text-white);
  font-family: var(--mobile-body);
 
}
/* .contact-info-frame {
  width: 322.3px;
  height: 395.3px;
} */




.contact-info-frame {
  border-radius: var(--br-3xs);
  width: 100%;
  height: 512px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs);
  box-sizing: border-box;
  position: relative;
  background-image: url(/public/section-11@3x.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  text-align: left;
  font-size: var(--mobile-body-size);
  color: var(--text-white);
  font-family: var(--mobile-body);
}

@media screen and (min-width: 768px) {
  .pagecontent{
    background-position: unset ;
    background-size: contain;
    
  }
  .text-area-mobile{
    width: 50%;
    top: unset;
  }
  
}

@media screen and (min-width: 768px) {
  .contact-info-frame{
    background-position-y: -30px ;
    background-size: contain;
   
    
  }
  .text-area-mobile{
    width: 50%;
    top: unset;
  }

  .contact-info {
    position: unset;
    height: auto;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 12.65%;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8.97746467590332px 0 0;
    box-sizing: border-box;
    gap: var(--gap-11xl);
    text-align: left;
    font-size: 28.83px;
    color: var(--text-white);
    font-family: var(--mobile-body);
}
}
