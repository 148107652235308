.welcome-to-cmr {
  margin-block-start: 0;
  margin-block-end: 6px;
}
.conveniently-located-in {
  margin: 0;
}
.content-div {
  flex: 1;
  position: relative;
}
.text-area-mobile {
  margin: 0 !important;
  position: absolute;
  top: 31px;
  left: calc(50% - 150px);
  width: 298px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 0;
}
.pagecontent {
  border-radius: var(--br-3xs);
  width: 100%;
  height: 512px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs);
  box-sizing: border-box;
  position: relative;
  background-image: url(/public/section-11@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: left;
  font-size: var(--mobile-body-size);
  color: var(--text-white);
  font-family: var(--mobile-body);
  overflow: auto;
}

@media screen and (min-width: 768px) {
  .pagecontent {
    background-position: unset;
    background-size: contain;
    overflow: auto;
  }
  .text-area-mobile {
    width: 50%;
    /* top: unsaet; */
  }
}
