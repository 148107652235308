.cmrlogo-icon18,
.menu-icon6 {
  max-width: 100%;
  overflow: hidden;
  height: 65px;
  object-fit: cover;
  
}

.menu-icon6-enter {
  opacity: 0;
}
.menu-icon6-enter-active {
  opacity: 1;
  transition: opacity 10000ms;
}
.menu-icon6-exit {
  opacity: 1;
}
.menu-icon6-exit-active {
  opacity: 0;
  transition: opacity 10000ms;
}

.menu-icon6 {
  height: 48.5px;
  transform: rotate(0deg);
  
  

  /* animation-name: spin; 
  animation-duration: 500ms;
  animation-iteration-count: 1;
  animation-timing-function: linear;  */
  
}
.rotate-icon{
  transform: rotate(90deg);
  transition-duration: 500ms;
  transition-timing-function: ease-in-out;
  /* transition-property: transform;
  transition-duration: 500ms;
  transition-timing-function: ease-in-out;
  transition-delay: 1s; */
 
  /* animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: 1;
  animation-timing-function: linear; */
}
/* @keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(90deg);}
} */

.property-1moble-navbar-closed6 {
  align-self: stretch;
  background-color: var(--color-black);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
@media screen and (min-width: 961px) {
  .property-1moble-navbar-closed6 {
    display: none;
  }
}
@media screen and (max-width: 960px) {
  .property-1moble-navbar-closed6 {
    display: flex;
  }
}
